import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { ref, get, getDatabase, child, onValue } from 'firebase/database';
import { db } from '../utils/firebase';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useTable, usePagination, useSortBy, useFilters } from 'react-table';
import { formatDate } from '../dateUtil.jsx';

// Function to format numbers as Brazilian real
function formatCurrency(value) {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
}

const Combustivels = () => {
  // State to store the data from Firebase
  const [data, setData] = useState([]);
  const [centrocustoFilter, setCentrocustoFilter] = useState(''); // State for centroCusto filter

  // Function to fetch data from Firebase
  useEffect(() => {
    console.log('centrocustoFilter:', centrocustoFilter);
    console.log('Data:', data);
    const fetchData = async () => {
      const compraRef = ref(db, 'compra');
      const compraSnapshot = await get(compraRef);

      if (compraSnapshot.exists()) {
        const compraIds = Object.keys(compraSnapshot.val());
        
        const fetchedData = [];
        
        for (const id of compraIds) {
          const specificCompraRef = ref(db, `compra/${id}`);
          const specificCompraSnapshot = await get(specificCompraRef);
          if (specificCompraSnapshot.exists()) {
            fetchedData.push({ id, ...specificCompraSnapshot.val() });
          }
        }
        
        // Filter data based on centrocustoFilter if filter is set
        const filteredData = centrocustoFilter ? fetchedData.filter(item => item.centrocusto === centrocustoFilter) : fetchedData;
        setData(filteredData);
      }
    };

    fetchData();
  }, [centrocustoFilter]); // Fetch data when centroCusto filter changes
  const customFilter = (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue.toLowerCase().includes(filterValue.toLowerCase());
    });
  };
  // Define columns
  // Define columns
const columns = useMemo(
  () => [
    { Header: 'ID Compra', accessor: 'id' },
    {
      Header: 'Data',
      accessor: 'data',
      Cell: ({ value }) => formatDate(value) 
    },
    { Header: 'Cupom', accessor: 'cupom' },
    { Header: 'Placa', accessor: 'placa' },
    { Header: 'Motorista', accessor: 'motorista', Cell: ({ value }) => value.toUpperCase() },
    { Header: 'Combustível', accessor: 'combustivel', Cell: ({ value }) => value.toUpperCase() },
    { Header: 'Tipo Veículo', accessor: 'veiculoTipo' , Cell: ({ value }) => value.toUpperCase()},
    { Header: 'Km/Horímetro', accessor: 'kmfinal' },
    { Header: 'Litros', accessor: 'litros', Cell: ({ value }) => value + ' L' },

    {
      Header: 'Valor Unitário',
      accessor: 'valoruni', Cell: ({ value }) => formatCurrency(value) 
    },
    {
      Header: 'Valor Total',
      accessor: 'valor', Cell: ({ value }) => formatCurrency(value) 
    },
   
    { Header: 'Centro Custo', accessor: 'centrocusto', Cell: ({ value }) => value.toLowerCase() },
    { Header: 'Posto', accessor: 'posto', Cell: ({ value }) => value.toUpperCase() },
  ],
  []
);
  // Initialize react-table with sorting and pagination
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { filters: [{ id: 'placa', value: '' }, { id: 'datacompra', value: '' }] }, // Initialize filter state for 'nome' and 'datacompra'
      filterTypes: { // Register custom filter type
        text: customFilter, // Use custom filter for text type
      },
    },
    useFilters, useSortBy,
    usePagination
  );

  // Destructure methods and state from tableInstance
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, pageOptions, page, nextPage, previousPage, canNextPage, canPreviousPage, state,setFilter } = tableInstance;

  const { pageIndex } = state;

  return (
    <div style={{ maxWidth: '97%', margin: '0 auto' }}>
      <h1 className="text-center mt-3" style={{ color: '#024102'}}>Controle de Combustível</h1>
      
      {/* Centro Custo filter */}
      <div className="text-center mb-3">
      <input
  type="date"
  placeholder="Data Início"
  onChange={(e) => setFilter('data', e.target.value)} // Set filter for 'data'
  style={{ padding: '5px', borderRadius: '3px', border: '1px solid #ccc', marginRight: '10px' }}
/>

      <input
          type="text"
          placeholder="Filtrar por Placa"
          onChange={(e) => setFilter('placa', e.target.value)} // Set filter for 'nome'
          style={{ padding: '5px', borderRadius: '3px', border: '1px solid #ccc', marginRight: '10px' }}
        />
      <input
          type="text"
          placeholder="Filtrar por Posto"
          onChange={(e) => setFilter('posto', e.target.value)} // Set filter for 'nome'
          style={{ padding: '5px', borderRadius: '3px', border: '1px solid #ccc', marginRight: '10px' }}
        />
<select
      value={centrocustoFilter}
      onChange={(e) => {
        console.log('centrocustoFilter:', e.target.value);
        setCentrocustoFilter(e.target.value);
      }}
      style={{ padding: '5px', borderRadius: '3px', border: '1px solid #ccc', marginRight: '10px' }}
    >
      <option value="">Selecione o Centro de Custo</option>
      <option value="1 - ADMINISTRAÇAO CENTRAL">1 - ADMINISTRAÇAO CENTRAL</option>
  <option value="2 - ADMINISTRAÇAO BASE LOCAL - ALAGOAS">2 - ADMINISTRAÇAO BASE LOCAL - ALAGOAS</option>
  <option value="3 - ADMINISTRAÇAO BASE LOCAL - RIO GRANDE DO SUL">3 - ADMINISTRAÇAO BASE LOCAL - RIO GRANDE DO SUL</option>
  <option value="11 - BRUNO GUERRERO">11 - BRUNO GUERRERO</option>
  <option value="12 - VICTOR GUERRERO">12 - VICTOR GUERRERO</option>
  <option value="13 - ROMINO GUERRERO">13 - ROMINO GUERRERO</option>
  <option value="14 - ROMEL GUERRERO">14 - ROMEL GUERRERO</option>
  <option value="15 - CUSTOS GERAL DE OBRAS">15 - CUSTOS GERAL DE OBRAS</option>
  <option value="98 - LT 230 KV MARITUBA / UTINGA - LIG GLOBAL">98 - LT 230 KV MARITUBA / UTINGA - LIG GLOBAL</option>
  <option value="99 - PATRIMONIO CENTRAL">99 - PATRIMONIO CENTRAL</option>
  <option value="100 - GUERRERO LOCACOES">100 - GUERRERO LOCACOES</option>
  <option value="101 - SISTEMA CASTANHAL - EQUATORIAL PA">101 - SISTEMA CASTANHAL - EQUATORIAL PA</option>
  <option value="102 - MANUTENCOES - SE's NORTE MARANHAO">102 - MANUTENCOES - SE's NORTE MARANHAO</option>
  <option value="103 - SISTEMA NORTE ALAGOAS - EQUATORIAL AL">103 - SISTEMA NORTE ALAGOAS - EQUATORIAL AL</option>
  <option value="104 - SUBESTAÇÃO BATISTA CAMPOS- EQUATORIAL PA">104 - SUBESTAÇÃO BATISTA CAMPOS- EQUATORIAL PA</option>
  <option value="105 - LD 69kV TORRES 2 - 5,4KM - EQUATORIAL RS">105 - LD 69kV TORRES 2 - 5,4KM - EQUATORIAL RS</option>
  <option value="106 - LPU LD 69 KV VIA MAO - AGUAS CLARAS - EQUATORIAL RS">106 - LPU LD 69 KV VIA MAO - AGUAS CLARAS - EQUATORIAL RS</option>
  <option value="107 - DESATIVAÇAO LD 69KV ANGELIM X VIÇOSA - EQUATORIAL AL">107 - DESATIVAÇAO LD 69KV ANGELIM X VIÇOSA - EQUATORIAL AL</option>
  <option value="108 - LD 69 KV MATRIZ DE CAMARAGIBE / PORTO CALVO - EQUATORIAL AL">108 - LD 69 KV MATRIZ DE CAMARAGIBE / PORTO CALVO - EQUATORIAL AL</option>
  <option value="109 - LD 69KV RIO LARGO II / CIDADE UNIVERSITARIA - EQUATORIAL AL">109 - LD 69KV RIO LARGO II / CIDADE UNIVERSITARIA - EQUATORIAL AL</option>
  <option value="110 - LD 69KV PORTO CALVO I MARAGOGI - EQUATORIAL AL">110 - LD 69KV PORTO CALVO I MARAGOGI - EQUATORIAL AL</option>
  <option value="111 - LPU - PACOTE 24 - EQUATORIAL/AL">111 - LPU - PACOTE 24 - EQUATORIAL/AL</option>
  <option value="112 - LD 69 KV PAL 14 I DEMAE - 8,3 KM - EQUATORIAL RS">112 - LD 69 KV PAL 14 I DEMAE - 8,3 KM - EQUATORIAL RS</option>
  <option value="113 - LD 69 KV RESTINGA - PAL 14 4,7 KM - EQUATORIAL/RS">113 - LD 69 KV RESTINGA - PAL 14 4,7 KM - EQUATORIAL/RS</option>
  <option value="114 - LD 69KV PAL 8 I PAL 17 - 2,9 KM - EQUATORIAL/RS">114 - LD 69KV PAL 8 I PAL 17 - 2,9 KM - EQUATORIAL/RS</option>
  <option value="115 - LT 138 KV - TESTA BRANCA - TABULEIROS II - SUBST. E-13 e E-14  - OMEGA ENERGIA">115 - LT 138 KV - TESTA BRANCA - TABULEIROS II - SUBST. E-13 e E-14  - OMEGA ENERGIA</option>
  <option value="116 - OBRA LD SSMC PEP - LUZIAPOLIS - EQUATORIAL AL">116 - OBRA LD SSMC PEP - LUZIAPOLIS - EQUATORIAL AL</option>
  <option value="117 - LPU UNIAO DOS PALMARES - EQUATORIAL AL">117 - LPU UNIAO DOS PALMARES - EQUATORIAL AL</option>
  <option value="118 - SERVIÇOS EXTRAS REGIONAL PARÁ - EQUATORIAL PA">118 - SERVIÇOS EXTRAS REGIONAL PARÁ - EQUATORIAL PA</option>
  <option value="120 - SERVIÇOS EXTRAS REGIONAL ALAGOAS - EQUATORIAL AL">120 - SERVIÇOS EXTRAS REGIONAL ALAGOAS - EQUATORIAL AL</option>
  <option value="121 - UTINGA - POLIMIX PARA">121 - UTINGA - POLIMIX PARA</option>
  <option value="122 - LD 69 KV OLHO DAGUA DAS FLORES - JACARÉ DOS HOMENS - EQUATORIAL AL">122 - LD 69 KV OLHO DAGUA DAS FLORES - JACARÉ DOS HOMENS - EQUATORIAL AL</option>
  <option value="123 - SERVIÇOS EXTRAS - REGIONAL RIO GRANDE DO SUL - EQUATORIAL">123 - SERVIÇOS EXTRAS - REGIONAL RIO GRANDE DO SUL - EQUATORIAL</option>
  <option value="124 - SERVIÇOS EXTRAS REGIONAL MARANHAO - EQUATORIAL MA">124 - SERVIÇOS EXTRAS REGIONAL MARANHAO - EQUATORIAL MA</option>
  <option value="125 - LD 138 KV PCH RETIRO VELHO SD CHAPADÃO DO SUL - BRASIL PCH">125 - LD 138 KV PCH RETIRO VELHO SD CHAPADÃO DO SUL - BRASIL PCH</option>
  <option value="126 - COMERCIAL / ENGENHARIA GUERRERO CONSTRUTORA">126 - COMERCIAL / ENGENHARIA GUERRERO CONSTRUTORA</option>
  <option value="127 - AREA DE CONVIVENCIA">127 - AREA DE CONVIVENCIA</option>
  <option value="128 - SE 138kV SALSO - EQUATORIAL RS">128 - SE 138kV SALSO - EQUATORIAL RS</option>
  <option value="129 - LD 138kV CAPIVARI DO SUL - EQUATORIAL RS">129 - LD 138kV CAPIVARI DO SUL - EQUATORIAL RS</option>
  <option value="130 - OBRA LD 69KV ARAPIRACA - CRAÍBAS - EQUATORIAL AL">130 - OBRA LD 69KV ARAPIRACA - CRAÍBAS - EQUATORIAL AL</option>
  <option value="131 - OBRA COMPOSIÇAO LD 69 KV PAL 14 I DEMAE - 8,3 KM - EQUATORIAL RS">131 - OBRA COMPOSIÇAO LD 69 KV PAL 14 I DEMAE - 8,3 KM - EQUATORIAL RS</option>
  <option value="132 - CONSTRUÇAO SE PORTO ALEGRE 17 - EQUATORIAL PORTO ALEGRE RS">132 - CONSTRUÇAO SE PORTO ALEGRE 17 - EQUATORIAL PORTO ALEGRE RS</option>
  <option value="133 - AMPLIAÇAO SE PORTO ALEGRE 14 - EQUATORIAL PORTO ALEGRE RS">133 - AMPLIAÇAO SE PORTO ALEGRE 14 - EQUATORIAL PORTO ALEGRE RS</option>
  <option value="134 - CONSTRUÇAO SE DMAE - EQUATORIAL PORTO ALEGRE RS">134 - CONSTRUÇAO SE DMAE - EQUATORIAL PORTO ALEGRE RS</option>
  <option value="135 - LD 69 KV MARECHAL DEODORO / BARRA DE SÃO MIGUEL - EQUATORIAL/AL">135 - LD 69 KV MARECHAL DEODORO / BARRA DE SÃO MIGUEL - EQUATORIAL/AL</option>
  <option value="136 - NOVO BAY 230KV SE ALBRÁS E SE ALUNORTE - HYDRO ALLUNORTE">136 - NOVO BAY 230KV SE ALBRÁS E SE ALUNORTE - HYDRO ALLUNORTE</option>
  <option value="137 - LT 230 KV ALBRAS - HYDRO ALUNORTE">137 - LT 230 KV ALBRAS - HYDRO ALUNORTE</option>
  <option value="138 - CONTENÇÃO DE ESTRUTURA 60/3 LD MRA X TMA - EQUATORIAL MA">138 - CONTENÇÃO DE ESTRUTURA 60/3 LD MRA X TMA - EQUATORIAL MA</option>
  <option value="139 - LD 69KV MATRIZ DE CAMARAGIBE / COSTA DOS CORAIS - EQUATORIAL AL">139 - LD 69KV MATRIZ DE CAMARAGIBE / COSTA DOS CORAIS - EQUATORIAL AL</option>
  <option value="140 - LD 69kV DELMIRO GOLVEIA - OLHO DAGUA DAS FLORES - EQUATORIAL AL">140 - LD 69kV DELMIRO GOLVEIA - OLHO DAGUA DAS FLORES - EQUATORIAL AL</option>
  <option value="141 - CONSTRUÇÃO SE BAGÉ 3 - EQUATORIAL RS">141 - CONSTRUÇÃO SE BAGÉ 3 - EQUATORIAL RS</option>
  <option value="142 - RENOVAÇÃO SE BAGÉ 1 - EQUATORIAL RS">142 - RENOVAÇÃO SE BAGÉ 1 - EQUATORIAL RS</option>
  <option value="143 - RENOVAÇÃO SE RIO GRANDE 1 - EQUATORIAL RS">143 - RENOVAÇÃO SE RIO GRANDE 1 - EQUATORIAL RS</option>
  <option value="144 - AMPLIAÇÃO SE SANTA VITÓRIA DO PALMAR - EQUATORIAL RS">144 - AMPLIAÇÃO SE SANTA VITÓRIA DO PALMAR - EQUATORIAL RS</option>
  <option value="145 - AMPLIAÇÃO SE TAIM 1 - EQUATORIAL RS">145 - AMPLIAÇÃO SE TAIM 1 - EQUATORIAL RS</option>
  <option value="146 - LD 138 KV OEIRAS X CAMETA - EQTL PARA">146 - LD 138 KV OEIRAS X CAMETA - EQTL PARA</option>
  <option value="147 - LD 69KV SÃO MIGUEL DOS CAMPOS X PERIPERI - EQUATORIAL AL">147 - LD 69KV SÃO MIGUEL DOS CAMPOS X PERIPERI - EQUATORIAL AL</option>
  <option value="148 - LPU AMAPÁ - EQUATORIAL AP">148 - LPU AMAPÁ - EQUATORIAL AP</option>
  <option value="149 - LT 69 KV CALHEIROS - BRASIL PCH - RJ">149 - LT 69 KV CALHEIROS - BRASIL PCH - RJ</option>              
  <option value="150 - LT PCH PIRAPETINGA X SE ITAPERUNA - ESSENTIA ENERGIA">150 - LT PCH PIRAPETINGA X SE ITAPERUNA - ESSENTIA ENERGIA</option>              
  <option value="151 - LD 69KV CAM1 - CAM3 - RS">151 - LD 69KV CAM1 - CAM3 - RS</option>   
  <option value="154 - LD JUSSARA/FAZ.CANADA - EQT - GO">154 - LD JUSSARA/FAZ.CANADA - EQT - GO</option>      
    <option value="155 - LD 138kV PCH Retiro Velho - Brasil PCH - GO">155 - LD 138kV PCH Retiro Velho - Brasil PCH - GO</option>      
    <option value="156 - SE BROTAS - 69 KV TIPO GIS - NEOENERGIA - BA">156 - SE BROTAS - 69 KV TIPO GIS - NEOENERGIA - BA</option>      
    <option value="157 - SERVIÇOS DE LPU - CONTRATO 570/2024 - ALAGOAS">157 - SERVIÇOS DE LPU - CONTRATO 570/2024 - ALAGOAS</option>      
    <option value="158 - LD 69kV CHARQUEADAS 3 - SECC CHA - GUA 1">158 - LD 69kV CHARQUEADAS 3 - SECC CHA - GUA 1</option>      
      
  
    </select>



      </div>
      
      <Table striped bordered hover size="sm" {...getTableProps()} style={{ fontSize: '14px' }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage} style={{ margin: '0 5px' }} variant="light">
          Previous
        </Button>
        <span>
          Page <strong>{pageIndex + 1}</strong> of <strong>{pageOptions.length}</strong>
        </span>
        <Button onClick={() => nextPage()} disabled={!canNextPage} style={{ margin: '0 5px' }} variant="light">
          Next
        </Button>
      </div>
    </div>
  );
};

export default Combustivels;
