
import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ref, set, onValue, remove, get,getDatabase, child} from 'firebase/database';
import { db } from '../utils/firebase';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import * as XLSX from 'xlsx';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { baixarRecord } from '../printUtil.jsx';
import { formatDate } from '../dateUtil.jsx';


Modal.setAppElement('#root');


const Pedidoss = () => {
  const searchInputRef = useRef(null);


  const [rowData, setRowData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [showRegistrarModalff, setShowRegistrarModalff] = useState(false);
  const [showRegistrarModal, setShowRegistrarModal] = useState(false);
  const [solicitante, setSolicitante] = useState('');
  const closeModal = () => {
    setModalIsOpen(false);
   
    setShowRegistrarModalff(false); 
    setShowRegistrarModal(false); 
  };
  const openRegistrarModal = () => {
    setShowRegistrarModal(true); 
  };
  const openRegistrarModalff = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
        const usersRef = ref(db, 'usersf');
        console.log("Reference:", usersRef); // Log the reference to ensure it's correct

        get(usersRef)
            .then((snapshot) => {
                // Log the snapshot to see what data it contains

                // Check if snapshot has data
                if (snapshot.exists()) {
                    // Assuming snapshot.val() returns the data, you can check permission here
                    const data = snapshot.val();
                    

                    // Check permission logic here, for example:
                    if (data && data[user.uid]) {
                      setShowRegistrarModalff(true);
                  } else {
                      console.error('You do not have permission to access this node.');
                  }
                  
                } else {
                    console.error('Node does not exist.');
                }
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
            });
    } else {
        console.error('User is not authenticated.');
    }
};



  
  
  
  const currentDateUTC = new Date(); // Get current date in UTC
  currentDateUTC.setHours(currentDateUTC.getHours() - 3); // Adjust for Brazilian time zone
  
  const currentDate = currentDateUTC.toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
  
  // Update the input field with ID 'datasolicitacao'
  const dateInput = document.getElementById('datasolicitacao');
  if (dateInput) {
    dateInput.value = currentDate;
  }
  const generateCustomKey = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2);
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    // Format the date and time components into a single string
    const key = `${day}${month}${year}_${hours}${minutes}${seconds}`;
    return key;
  };
  

  // ... other state and functions ...

  




 
  
 const submitFormDataToFirebaseff = (formData) => {
  const customKey = generateCustomKey();
  const newRecordRef = ref(db, `controlecustosfrotas/${customKey}`);

  if (!formData.idpedido ) {
    alert('Estão faltando dados obrigatórios');
    return;
  }

  set(newRecordRef, formData)
   .then(() => {
      console.log('Data added to Firebase successfully!');
      alert('Enviado!')
      closeModal();
    })
   .catch(error => {
      console.error('Error adding data to Firebase:', error);
    });
};
 


return (
  <>
    {/* Registrar Modal */}
   


    <Modal 
  isOpen={showRegistrarModalff}
  onRequestClose={closeModal}
  contentLabel="Registrar Modal"
  style={{
    content: {
      size:'80%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '50%', // Adjust the maximum width as needed
      maxHeight: '80%', // Set the maximum height to enable scrolling
      height: 'auto', // Allow the height to adjust based on content
      width: 'auto', // Allow the height to adjust based on content
      padding: '10px', // Reduce padding
      backgroundColor: 'white',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
     
    }
  }}
>
  <div className="modal-content" >
    {/* Content for the registrar modal */}
    
    {/* Dados Passagem */}
    <h5 className="section-title" style={{ textAlign: 'center', padding: '10px', color:'#024102' }}>Dados do Pedido de Compra</h5>
  
    <Form>
    <Row className="mb-3" style={{backgroundColor: '#e9ecef', padding: '07px'}}>
      {/* ID */}
      <Form.Group as={Col} xs={12} md={4} style={{ marginBottom: '1rem' }}>
        <Form.Label>N° Pedido</Form.Label>
        <Form.Control type="text" id="idpedido"  size="sm" />
      </Form.Group>
      
 
      
      {/* Comprador */}
      <Form.Group as={Col} xs={12} md={4} style={{ marginBottom: '1rem' }}>
        <Form.Label>Natureza</Form.Label>
        <Form.Select id="natureza" size="sm">
          <option selected></option>
          <option value="Preditiva">Manutenção Preditiva</option>
          <option value="Corretiva">Manutenção Corretiva</option>
          <option value="Preventiva">Manutenção Preventiva</option>
          <option value="Detectiva">Manutenção Detectiva</option>
  
    <option value="Acessórios">Acessórios</option>
    <option value="Outros">Outros</option>
        </Form.Select>
      </Form.Group>
      <Form.Group as={Col} xs={12} md={4} style={{ marginBottom: '1rem' }}>
        <Form.Label>Emergencial?</Form.Label>
        <Form.Select id="emergencial" size="sm">
          <option selected></option>
          <option value="Sim">Sim</option>
    <option value="Não">Não</option>
        </Form.Select>
      </Form.Group>
      </Row>
      <Row className="mb-3" style={{ padding: '07px'}}>
      <Form.Group as={Col} xs={12} md={12} style={{ marginBottom: '1rem' }}>
        <Form.Label>Centro de Custo <span style={{ color: 'red', fontSize:'12px'}}> *CC despesa associado</span></Form.Label>
        <Form.Select id="despesacc"  size="sm" >
   
  <option value="1 - ADMINISTRAÇAO CENTRAL">1 - ADMINISTRAÇAO CENTRAL</option>
    <option value="2 - ADMINISTRAÇAO BASE LOCAL - ALAGOAS">2 - ADMINISTRAÇAO BASE LOCAL - ALAGOAS</option>
    <option value="3 - ADMINISTRAÇAO BASE LOCAL - RIO GRANDE DO SUL">3 - ADMINISTRAÇAO BASE LOCAL - RIO GRANDE DO SUL</option>
    <option value="11 - BRUNO GUERRERO">11 - BRUNO GUERRERO</option>
    <option value="12 - VICTOR GUERRERO">12 - VICTOR GUERRERO</option>
    <option value="13 - ROMINO GUERRERO">13 - ROMINO GUERRERO</option>
    <option value="14 - ROMEL GUERRERO">14 - ROMEL GUERRERO</option>
    <option value="15 - CUSTOS GERAL DE OBRAS">15 - CUSTOS GERAL DE OBRAS</option>
    <option value="98 - LT 230 KV MARITUBA / UTINGA - LIG GLOBAL">98 - LT 230 KV MARITUBA / UTINGA - LIG GLOBAL</option>
    <option value="99 - PATRIMONIO CENTRAL">99 - PATRIMONIO CENTRAL</option>
    <option value="100 - GUERRERO LOCACOES">100 - GUERRERO LOCACOES</option>
    <option value="101 - SISTEMA CASTANHAL - EQUATORIAL PA">101 - SISTEMA CASTANHAL - EQUATORIAL PA</option>
    <option value="102 - MANUTENCOES - SE's NORTE MARANHAO">102 - MANUTENCOES - SE's NORTE MARANHAO</option>
    <option value="103 - SISTEMA NORTE ALAGOAS - EQUATORIAL AL">103 - SISTEMA NORTE ALAGOAS - EQUATORIAL AL</option>
    <option value="104 - SUBESTAÇÃO BATISTA CAMPOS- EQUATORIAL PA">104 - SUBESTAÇÃO BATISTA CAMPOS- EQUATORIAL PA</option>
    <option value="105 - LD 69kV TORRES 2 - 5,4KM - EQUATORIAL RS">105 - LD 69kV TORRES 2 - 5,4KM - EQUATORIAL RS</option>
    <option value="106 - LPU LD 69 KV VIA MAO - AGUAS CLARAS - EQUATORIAL RS">106 - LPU LD 69 KV VIA MAO - AGUAS CLARAS - EQUATORIAL RS</option>
    <option value="107 - DESATIVAÇAO LD 69KV ANGELIM X VIÇOSA - EQUATORIAL AL">107 - DESATIVAÇAO LD 69KV ANGELIM X VIÇOSA - EQUATORIAL AL</option>
    <option value="108 - LD 69 KV MATRIZ DE CAMARAGIBE / PORTO CALVO - EQUATORIAL AL">108 - LD 69 KV MATRIZ DE CAMARAGIBE / PORTO CALVO - EQUATORIAL AL</option>
    <option value="109 - LD 69KV RIO LARGO II / CIDADE UNIVERSITARIA - EQUATORIAL AL">109 - LD 69KV RIO LARGO II / CIDADE UNIVERSITARIA - EQUATORIAL AL</option>
    <option value="110 - LD 69KV PORTO CALVO I MARAGOGI - EQUATORIAL AL">110 - LD 69KV PORTO CALVO I MARAGOGI - EQUATORIAL AL</option>
    <option value="111 - LPU - PACOTE 24 - EQUATORIAL/AL">111 - LPU - PACOTE 24 - EQUATORIAL/AL</option>
    <option value="112 - LD 69 KV PAL 14 I DEMAE - 8,3 KM - EQUATORIAL RS">112 - LD 69 KV PAL 14 I DEMAE - 8,3 KM - EQUATORIAL RS</option>
    <option value="113 - LD 69 KV RESTINGA - PAL 14 4,7 KM - EQUATORIAL/RS">113 - LD 69 KV RESTINGA - PAL 14 4,7 KM - EQUATORIAL/RS</option>
    <option value="114 - LD 69KV PAL 8 I PAL 17 - 2,9 KM - EQUATORIAL/RS">114 - LD 69KV PAL 8 I PAL 17 - 2,9 KM - EQUATORIAL/RS</option>
    <option value="115 - LT 138 KV - TESTA BRANCA - TABULEIROS II - SUBST. E-13 e E-14  - OMEGA ENERGIA">115 - LT 138 KV - TESTA BRANCA - TABULEIROS II - SUBST. E-13 e E-14  - OMEGA ENERGIA</option>
    <option value="116 - OBRA LD SSMC PEP - LUZIAPOLIS - EQUATORIAL AL">116 - OBRA LD SSMC PEP - LUZIAPOLIS - EQUATORIAL AL</option>
    <option value="117 - LPU UNIAO DOS PALMARES - EQUATORIAL AL">117 - LPU UNIAO DOS PALMARES - EQUATORIAL AL</option>
    <option value="118 - SERVIÇOS EXTRAS REGIONAL PARÁ - EQUATORIAL PA">118 - SERVIÇOS EXTRAS REGIONAL PARÁ - EQUATORIAL PA</option>
    <option value="120 - SERVIÇOS EXTRAS REGIONAL ALAGOAS - EQUATORIAL AL">120 - SERVIÇOS EXTRAS REGIONAL ALAGOAS - EQUATORIAL AL</option>
    <option value="121 - UTINGA - POLIMIX PARA">121 - UTINGA - POLIMIX PARA</option>
    <option value="122 - LD 69 KV OLHO DAGUA DAS FLORES - JACARÉ DOS HOMENS - EQUATORIAL AL">122 - LD 69 KV OLHO DAGUA DAS FLORES - JACARÉ DOS HOMENS - EQUATORIAL AL</option>
    <option value="123 - SERVIÇOS EXTRAS - REGIONAL RIO GRANDE DO SUL - EQUATORIAL">123 - SERVIÇOS EXTRAS - REGIONAL RIO GRANDE DO SUL - EQUATORIAL</option>
    <option value="124 - SERVIÇOS EXTRAS REGIONAL MARANHAO - EQUATORIAL MA">124 - SERVIÇOS EXTRAS REGIONAL MARANHAO - EQUATORIAL MA</option>
    <option value="125 - LD 138 KV PCH RETIRO VELHO SD CHAPADÃO DO SUL - BRASIL PCH">125 - LD 138 KV PCH RETIRO VELHO SD CHAPADÃO DO SUL - BRASIL PCH</option>
    <option value="126 - COMERCIAL / ENGENHARIA GUERRERO CONSTRUTORA">126 - COMERCIAL / ENGENHARIA GUERRERO CONSTRUTORA</option>
    <option value="127 - AREA DE CONVIVENCIA">127 - AREA DE CONVIVENCIA</option>
    <option value="128 - SE 138kV SALSO - EQUATORIAL RS">128 - SE 138kV SALSO - EQUATORIAL RS</option>
    <option value="129 - LD 138kV CAPIVARI DO SUL - EQUATORIAL RS">129 - LD 138kV CAPIVARI DO SUL - EQUATORIAL RS</option>
    <option value="130 - OBRA LD 69KV ARAPIRACA - CRAÍBAS - EQUATORIAL AL">130 - OBRA LD 69KV ARAPIRACA - CRAÍBAS - EQUATORIAL AL</option>
    <option value="131 - OBRA COMPOSIÇAO LD 69 KV PAL 14 I DEMAE - 8,3 KM - EQUATORIAL RS">131 - OBRA COMPOSIÇAO LD 69 KV PAL 14 I DEMAE - 8,3 KM - EQUATORIAL RS</option>
    <option value="132 - CONSTRUÇAO SE PORTO ALEGRE 17 - EQUATORIAL PORTO ALEGRE RS">132 - CONSTRUÇAO SE PORTO ALEGRE 17 - EQUATORIAL PORTO ALEGRE RS</option>
    <option value="133 - AMPLIAÇAO SE PORTO ALEGRE 14 - EQUATORIAL PORTO ALEGRE RS">133 - AMPLIAÇAO SE PORTO ALEGRE 14 - EQUATORIAL PORTO ALEGRE RS</option>
    <option value="134 - CONSTRUÇAO SE DMAE - EQUATORIAL PORTO ALEGRE RS">134 - CONSTRUÇAO SE DMAE - EQUATORIAL PORTO ALEGRE RS</option>
    <option value="135 - LD 69 KV MARECHAL DEODORO / BARRA DE SÃO MIGUEL - EQUATORIAL/AL">135 - LD 69 KV MARECHAL DEODORO / BARRA DE SÃO MIGUEL - EQUATORIAL/AL</option>
    <option value="136 - NOVO BAY 230KV SE ALBRÁS E SE ALUNORTE - HYDRO ALLUNORTE">136 - NOVO BAY 230KV SE ALBRÁS E SE ALUNORTE - HYDRO ALLUNORTE</option>
    <option value="137 - LT 230 KV ALBRAS - HYDRO ALUNORTE">137 - LT 230 KV ALBRAS - HYDRO ALUNORTE</option>
    <option value="138 - CONTENÇÃO DE ESTRUTURA 60/3 LD MRA X TMA - EQUATORIAL MA">138 - CONTENÇÃO DE ESTRUTURA 60/3 LD MRA X TMA - EQUATORIAL MA</option>
    <option value="139 - LD 69KV MATRIZ DE CAMARAGIBE / COSTA DOS CORAIS - EQUATORIAL AL">139 - LD 69KV MATRIZ DE CAMARAGIBE / COSTA DOS CORAIS - EQUATORIAL AL</option>
    <option value="140 - LD 69kV DELMIRO GOLVEIA - OLHO DAGUA DAS FLORES - EQUATORIAL AL">140 - LD 69kV DELMIRO GOLVEIA - OLHO DAGUA DAS FLORES - EQUATORIAL AL</option>
    <option value="141 - CONSTRUÇÃO SE BAGÉ 3 - EQUATORIAL RS">141 - CONSTRUÇÃO SE BAGÉ 3 - EQUATORIAL RS</option>
    <option value="142 - RENOVAÇÃO SE BAGÉ 1 - EQUATORIAL RS">142 - RENOVAÇÃO SE BAGÉ 1 - EQUATORIAL RS</option>
    <option value="143 - RENOVAÇÃO SE RIO GRANDE 1 - EQUATORIAL RS">143 - RENOVAÇÃO SE RIO GRANDE 1 - EQUATORIAL RS</option>
    <option value="144 - AMPLIAÇÃO SE SANTA VITÓRIA DO PALMAR - EQUATORIAL RS">144 - AMPLIAÇÃO SE SANTA VITÓRIA DO PALMAR - EQUATORIAL RS</option>
    <option value="145 - AMPLIAÇÃO SE TAIM 1 - EQUATORIAL RS">145 - AMPLIAÇÃO SE TAIM 1 - EQUATORIAL RS</option>
    <option value="146 - LD 138 KV OEIRAS X CAMETA - EQTL PARA">146 - LD 138 KV OEIRAS X CAMETA - EQTL PARA</option>
    <option value="147 - LD 69KV SÃO MIGUEL DOS CAMPOS X PERIPERI - EQUATORIAL AL">147 - LD 69KV SÃO MIGUEL DOS CAMPOS X PERIPERI - EQUATORIAL AL</option>
    <option value="148 - LPU AMAPÁ - EQUATORIAL AP">148 - LPU AMAPÁ - EQUATORIAL AP</option>
    <option value="149 - LT 69 KV CALHEIROS - BRASIL PCH - RJ">149 - LT 69 KV CALHEIROS - BRASIL PCH - RJ</option>              
    <option value="150 - LT PCH PIRAPETINGA X SE ITAPERUNA - ESSENTIA ENERGIA">150 - LT PCH PIRAPETINGA X SE ITAPERUNA - ESSENTIA ENERGIA</option>              
    <option value="151 - LD 69KV CAM1 - CAM3 - RS">151 - LD 69KV CAM1 - CAM3 - RS</option>      
       </Form.Select>
      </Form.Group>
      </Row>
    <Row className="mb-3" style={{backgroundColor: '#e9ecef', padding: '07px'}}>
           {/* Data Solicitação */}
           <Form.Group as={Col} xs={12} md={4} style={{ marginBottom: '1rem' }}>
        <Form.Label>Placa</Form.Label>
        <Form.Control type="text" id="placafrota" size="sm" />
      </Form.Group>
      {/* ID */}
      <Form.Group as={Col} xs={12} md={4} style={{ marginBottom: '1rem' }}>
        <Form.Label>Motorista</Form.Label>
        <Form.Control type="text" id="motorista"  size="sm" />
      </Form.Group>
      <Form.Group as={Col} xs={12} md={4} style={{ marginBottom: '1rem' }}>
        <Form.Label>Responsável</Form.Label>
        <Form.Control type="text" id="responsavel"  size="sm" />
      </Form.Group>
      
  
      </Row>
      


    {/* Second row */}
    <Row className="mb-3" style={{padding: '07px'}}>
    <Form.Group as={Col} xs={12} md={12} style={{ marginBottom: '1rem' }}>
    <Form.Label>Observações</Form.Label>
    <Form.Control
      as="textarea"
      id="obsfrota"
       style={{ height: '2cm' }} // Set the height to 5cm
    />
  </Form.Group>

      <button
  type="button"
  style={{ textAlign: 'center' , marginTop:'20px'}}
  className="btn btn-primary btn-dark rounded"
  id="submit-button-class"
  onClick={() => {
    
    const formData = {

      idpedido: document.getElementById('idpedido').value,
      placafrota: document.getElementById('placafrota').value,
      natureza: document.getElementById('natureza').value,
      emergencial: document.getElementById('emergencial').value,
      motorista: document.getElementById('motorista').value,
      responsavel: document.getElementById('responsavel').value,
      despesacc: document.getElementById('despesacc').value,
     
      obsfrota: document.getElementById('obsfrota').value,
    };  
    submitFormDataToFirebaseff(formData);
  }}

>
  Enviar
</button>
    </Row>
    </Form>  
  </div>

</Modal>
      {/* Modal */}
      {/* Modal for adding data */}
     



<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
  <div style={{ width: '80%' }} className="mt-2" id="app">
    <h1 className="text-center mt-3" style={{ color: '#024102'}}>Controle Pedidos Frotas</h1>
    
    <div className="row">
   
      <div className="col-md-4 mt-3 d-flex align-items-end">
        <div className="input-group">

          <button
            type="button"
            style={{ zIndex:0 }}
            className="btn btn-success me-2 rounded"
            onClick={openRegistrarModalff}
          >
            Registrar
          </button>
          <button
  type="button"
  style={{ zIndex: 0 }}
  className="btn btn-dark me-2 rounded"
  onClick={() => window.location.href = 'https://guerrero.tesfire.com/compra'}
>
  Combustível
</button>

         


        </div>
      </div>
    </div>

    <div className="mt-2" id="data-table"></div>
    {/* Add your include("addForm") and include("editForm") here */}
  </div>
</div>
 </>
  );
};
export default Pedidoss;