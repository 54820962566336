import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ref, get, set, remove } from 'firebase/database';
import { db } from '../utils/firebase';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

Modal.setAppElement('#root');

const Compras = () => {
  const [formData, setFormData] = useState({ 
    id: '', 
    data: '', 
    motorista: '', 
  
    combustivel: '', 
    veiculoTipo: '', 
   
    kmfinal: '', 
    obscomb: '', 
    litros: '', 
    cupom: '', 
    valor:'',
    valoruni:'',
    placa: '', 
    horimetro: '', 
    posto: '',
    centrocustocomb: ''
  });
  const [userEmail, setUserEmail] = useState('');
  

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUserEmail(user.email);
      } else {
        // No user is signed in
        setUserEmail('');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const fillData = () => {
    const id = document.getElementById('id').value;
    const recordRef = ref(db, `compra/${id}`);

    get(recordRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setFormData({ 
            id, 
            data: data.data,
            motorista: data.motorista,
           
            combustivel: data.combustivel,
            veiculoTipo: data.veiculoTipo,
           
            kmfinal: data.kmfinal,
            obscomb: data.obscomb,
            litros: data.litros,
            cupom: data.cupom,
            valor: data.valor,
            valoruni: data.valoruni,
            placa: data.placa,
            horimetro: data.horimetro,
            posto: data.posto,
            centrocustocomb: data.centrocustocomb
          });
        } else {
          console.log('No data available');
          window.location.reload(); 
          // You can show a message or handle the case where data doesn't exist
        }
      })
      .catch((error) => {
        console.error('Error getting data:', error);
      });
};

  const deleteData = () => {
    const id = formData.id;
  
    if (!id) {
      alert('ID is required');
      return;
    }
  
    const recordRef = ref(db, `compra/${id}`);
    const deletedRecordRef = ref(db, `compradeleted/${id}`);
  
    get(recordRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          set(deletedRecordRef, { 
            id,
            data: data.data,
            motorista: data.motorista,
          
            combustivel: data.combustivel,
            veiculoTipo: data.veiculoTipo,
           
            kmfinal: data.kmfinal,
            obscomb: data.obscomb,
            litros: data.litros,
            cupom: data.cupom,
            valor: data.valor,
            valoruni: data.valoruni,
            placa: data.placa,
            horimetro: data.horimetro,
            posto: data.posto,
            userEmail,
            centrocustocomb: data.centrocustocomb
          })
            .then(() => {
              window.location.reload(); 
              console.log('Record moved to compradeleted successfully!');
            })
            .catch((error) => {
              console.error('Error moving record to compradeleted:', error);
            });
        } else {
          console.log('No data available for deletion');
          // You can show a message or handle the case where data doesn't exist for deletion
        }
      })
      .catch((error) => {
        console.error('Error getting data for deletion:', error);
      });
  
    remove(recordRef)
      .then(() => {
        console.log('Record deleted successfully!');
        alert('Record deleted successfully!');
        setFormData({ 
          id: '',
          data: '',
          motorista: '',
        
          combustivel: '',
          veiculoTipo: '',
        
          obscomb: '',
          kmfinal: '',
          litros: '',
          cupom: '',
          valor: '',
          valoruni: '',
          placa: '',
          horimetro: '',
          posto: '',
          centrocustocomb: ''
        }); // Clear the form after deletion
      })
      .catch((error) => {
        console.error('Error deleting record:', error);
      });
};

  const submitFormDataToFirebase = () => {
    const valor = formData.valoruni * formData.litros; 
    const { id, data, motorista,  combustivel, veiculoTipo,  kmfinal, obscomb , litros, cupom,valoruni, placa, horimetro, posto, centrocustocomb} = formData;
  
    if (!motorista || !combustivel || !data || !cupom || !placa ) {
      alert('Motorista, Combustivel, Data, Cupom, Placa, Centro de Custo, and Posto are required fields');
      return;
    }
  
    // If id is provided, it's an existing record being updated
    if (id) {
      const changedRecordKey = generateCustomKey();
      const changedRecordRef = ref(db, `comprachanged/${changedRecordKey}`);
  
      // Store the previous values with a custom key in the 'comprachanged' database
      set(changedRecordRef, { prevId: formData.id, data: formData.data, motorista: formData.motorista, combustivel: formData.combustivel, veiculoTipo: formData.veiculoTipo, kmfinal: formData.kmfinal,obscomb: formData.obscomb, litros: formData.litros, cupom: formData.cupom, valor: formData.valor, valoruni: formData.valoruni, placa: formData.placa, horimetro: formData.horimetro, posto: formData.posto, userEmail, centrocustocomb: formData.centrocustocomb })
        .then(() => {
          console.log('Previous values stored in comprachanged database successfully!');
        })
        .catch((error) => {
          console.error('Error storing previous values in comprachanged database:', error);
        });
    }
  
    const recordRef = ref(db, `compra/${id || generateCustomKey()}`);
    set(recordRef, { data, motorista,  combustivel, veiculoTipo,  kmfinal, litros, cupom, valor,valoruni, placa, horimetro,obscomb, posto, userEmail, centrocustocomb})
      .then(() => {
        console.log('Record updated in Firebase successfully!');
        alert('Solicitação atualizada com sucesso!');
        setFormData({ id: '', data: '', motorista: '',  combustivel: '', veiculoTipo: '',  kmfinal: '', obscomb:'',litros: '', cupom: '', valor:valoruni * litros,valoruni:'', placa: '', horimetro: '', posto: '', centrocustocomb: '' });
        window.location.reload();  // Clear the form after submission
      })
      .catch((error) => {
        console.error('Error updating record in Firebase:', error);
      });
  };

  
  

  const generateCustomKey = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2);
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    // Format the date and time components into a single string
    const key = `${day}${month}${year}_${hours}${minutes}${seconds}`;
    return key;
  };

  return (
    <>
      <div style={{ 
        backgroundImage: `url(${process.env.PUBLIC_URL}/green-light.jpg)`,
        backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      minHeight: '100vh',
      padding: '20px'
    }}>
      <Form className="my-4">
      <Row className="mb-3 align-items-center">
          <Form.Group as={Col} xs={12} md={2} className="mb-0">
            <Form.Label htmlFor="id" className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>ID</Form.Label>
            <div className="input-group">
              <Form.Control type="text" id="id" size="sm" className="form-control form-control-sm" style={{ fontSize: '0.9rem' }} />
              <Button 
  variant="secondary" 
  type="button" 
  className="btn btn-primary btn-sm"
  onClick={fillData}
>
  Search
</Button>

            </div>
          </Form.Group><Form.Group as={Col} xs={12} md={6} controlId="centrocustocomb" className="mb-0">
  <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Centro Custo</Form.Label>
  <Form.Select
    placeholder="Centro de Custo"
    name="centrocustocomb"
    value={formData.centrocustocomb}
    onChange={handleInputChange}
    className="form-select form-select-sm"
    style={{ fontSize: '0.9rem' }}
  >  <option selected value=""></option>
  <option value="1 - ADMINISTRAÇAO CENTRAL">1 - ADMINISTRAÇAO CENTRAL</option>
    <option value="2 - ADMINISTRAÇAO BASE LOCAL - ALAGOAS">2 - ADMINISTRAÇAO BASE LOCAL - ALAGOAS</option>
    <option value="3 - ADMINISTRAÇAO BASE LOCAL - RIO GRANDE DO SUL">3 - ADMINISTRAÇAO BASE LOCAL - RIO GRANDE DO SUL</option>
    <option value="11 - BRUNO GUERRERO">11 - BRUNO GUERRERO</option>
    <option value="12 - VICTOR GUERRERO">12 - VICTOR GUERRERO</option>
    <option value="13 - ROMINO GUERRERO">13 - ROMINO GUERRERO</option>
    <option value="14 - ROMEL GUERRERO">14 - ROMEL GUERRERO</option>
    <option value="15 - CUSTOS GERAL DE OBRAS">15 - CUSTOS GERAL DE OBRAS</option>
    <option value="98 - LT 230 KV MARITUBA / UTINGA - LIG GLOBAL">98 - LT 230 KV MARITUBA / UTINGA - LIG GLOBAL</option>
    <option value="99 - PATRIMONIO CENTRAL">99 - PATRIMONIO CENTRAL</option>
    <option value="100 - GUERRERO LOCACOES">100 - GUERRERO LOCACOES</option>
    <option value="101 - SISTEMA CASTANHAL - EQUATORIAL PA">101 - SISTEMA CASTANHAL - EQUATORIAL PA</option>
    <option value="102 - MANUTENCOES - SE's NORTE MARANHAO">102 - MANUTENCOES - SE's NORTE MARANHAO</option>
    <option value="103 - SISTEMA NORTE ALAGOAS - EQUATORIAL AL">103 - SISTEMA NORTE ALAGOAS - EQUATORIAL AL</option>
    <option value="104 - SUBESTAÇÃO BATISTA CAMPOS- EQUATORIAL PA">104 - SUBESTAÇÃO BATISTA CAMPOS- EQUATORIAL PA</option>
    <option value="105 - LD 69kV TORRES 2 - 5,4KM - EQUATORIAL RS">105 - LD 69kV TORRES 2 - 5,4KM - EQUATORIAL RS</option>
    <option value="106 - LPU LD 69 KV VIA MAO - AGUAS CLARAS - EQUATORIAL RS">106 - LPU LD 69 KV VIA MAO - AGUAS CLARAS - EQUATORIAL RS</option>
    <option value="107 - DESATIVAÇAO LD 69KV ANGELIM X VIÇOSA - EQUATORIAL AL">107 - DESATIVAÇAO LD 69KV ANGELIM X VIÇOSA - EQUATORIAL AL</option>
    <option value="108 - LD 69 KV MATRIZ DE CAMARAGIBE / PORTO CALVO - EQUATORIAL AL">108 - LD 69 KV MATRIZ DE CAMARAGIBE / PORTO CALVO - EQUATORIAL AL</option>
    <option value="109 - LD 69KV RIO LARGO II / CIDADE UNIVERSITARIA - EQUATORIAL AL">109 - LD 69KV RIO LARGO II / CIDADE UNIVERSITARIA - EQUATORIAL AL</option>
    <option value="110 - LD 69KV PORTO CALVO I MARAGOGI - EQUATORIAL AL">110 - LD 69KV PORTO CALVO I MARAGOGI - EQUATORIAL AL</option>
    <option value="111 - LPU - PACOTE 24 - EQUATORIAL/AL">111 - LPU - PACOTE 24 - EQUATORIAL/AL</option>
    <option value="112 - LD 69 KV PAL 14 I DEMAE - 8,3 KM - EQUATORIAL RS">112 - LD 69 KV PAL 14 I DEMAE - 8,3 KM - EQUATORIAL RS</option>
    <option value="113 - LD 69 KV RESTINGA - PAL 14 4,7 KM - EQUATORIAL/RS">113 - LD 69 KV RESTINGA - PAL 14 4,7 KM - EQUATORIAL/RS</option>
    <option value="114 - LD 69KV PAL 8 I PAL 17 - 2,9 KM - EQUATORIAL/RS">114 - LD 69KV PAL 8 I PAL 17 - 2,9 KM - EQUATORIAL/RS</option>
    <option value="115 - LT 138 KV - TESTA BRANCA - TABULEIROS II - SUBST. E-13 e E-14  - OMEGA ENERGIA">115 - LT 138 KV - TESTA BRANCA - TABULEIROS II - SUBST. E-13 e E-14  - OMEGA ENERGIA</option>
    <option value="116 - OBRA LD SSMC PEP - LUZIAPOLIS - EQUATORIAL AL">116 - OBRA LD SSMC PEP - LUZIAPOLIS - EQUATORIAL AL</option>
    <option value="117 - LPU UNIAO DOS PALMARES - EQUATORIAL AL">117 - LPU UNIAO DOS PALMARES - EQUATORIAL AL</option>
    <option value="118 - SERVIÇOS EXTRAS REGIONAL PARÁ - EQUATORIAL PA">118 - SERVIÇOS EXTRAS REGIONAL PARÁ - EQUATORIAL PA</option>
    <option value="120 - SERVIÇOS EXTRAS REGIONAL ALAGOAS - EQUATORIAL AL">120 - SERVIÇOS EXTRAS REGIONAL ALAGOAS - EQUATORIAL AL</option>
    <option value="121 - UTINGA - POLIMIX PARA">121 - UTINGA - POLIMIX PARA</option>
    <option value="122 - LD 69 KV OLHO DAGUA DAS FLORES - JACARÉ DOS HOMENS - EQUATORIAL AL">122 - LD 69 KV OLHO DAGUA DAS FLORES - JACARÉ DOS HOMENS - EQUATORIAL AL</option>
    <option value="123 - SERVIÇOS EXTRAS - REGIONAL RIO GRANDE DO SUL - EQUATORIAL">123 - SERVIÇOS EXTRAS - REGIONAL RIO GRANDE DO SUL - EQUATORIAL</option>
    <option value="124 - SERVIÇOS EXTRAS REGIONAL MARANHAO - EQUATORIAL MA">124 - SERVIÇOS EXTRAS REGIONAL MARANHAO - EQUATORIAL MA</option>
    <option value="125 - LD 138 KV PCH RETIRO VELHO SD CHAPADÃO DO SUL - BRASIL PCH">125 - LD 138 KV PCH RETIRO VELHO SD CHAPADÃO DO SUL - BRASIL PCH</option>
    <option value="126 - COMERCIAL / ENGENHARIA GUERRERO CONSTRUTORA">126 - COMERCIAL / ENGENHARIA GUERRERO CONSTRUTORA</option>
    <option value="127 - AREA DE CONVIVENCIA">127 - AREA DE CONVIVENCIA</option>
    <option value="128 - SE 138kV SALSO - EQUATORIAL RS">128 - SE 138kV SALSO - EQUATORIAL RS</option>
    <option value="129 - LD 138kV CAPIVARI DO SUL - EQUATORIAL RS">129 - LD 138kV CAPIVARI DO SUL - EQUATORIAL RS</option>
    <option value="130 - OBRA LD 69KV ARAPIRACA - CRAÍBAS - EQUATORIAL AL">130 - OBRA LD 69KV ARAPIRACA - CRAÍBAS - EQUATORIAL AL</option>
    <option value="131 - OBRA COMPOSIÇAO LD 69 KV PAL 14 I DEMAE - 8,3 KM - EQUATORIAL RS">131 - OBRA COMPOSIÇAO LD 69 KV PAL 14 I DEMAE - 8,3 KM - EQUATORIAL RS</option>
    <option value="132 - CONSTRUÇAO SE PORTO ALEGRE 17 - EQUATORIAL PORTO ALEGRE RS">132 - CONSTRUÇAO SE PORTO ALEGRE 17 - EQUATORIAL PORTO ALEGRE RS</option>
    <option value="133 - AMPLIAÇAO SE PORTO ALEGRE 14 - EQUATORIAL PORTO ALEGRE RS">133 - AMPLIAÇAO SE PORTO ALEGRE 14 - EQUATORIAL PORTO ALEGRE RS</option>
    <option value="134 - CONSTRUÇAO SE DMAE - EQUATORIAL PORTO ALEGRE RS">134 - CONSTRUÇAO SE DMAE - EQUATORIAL PORTO ALEGRE RS</option>
    <option value="135 - LD 69 KV MARECHAL DEODORO / BARRA DE SÃO MIGUEL - EQUATORIAL/AL">135 - LD 69 KV MARECHAL DEODORO / BARRA DE SÃO MIGUEL - EQUATORIAL/AL</option>
    <option value="136 - NOVO BAY 230KV SE ALBRÁS E SE ALUNORTE - HYDRO ALLUNORTE">136 - NOVO BAY 230KV SE ALBRÁS E SE ALUNORTE - HYDRO ALLUNORTE</option>
    <option value="137 - LT 230 KV ALBRAS - HYDRO ALUNORTE">137 - LT 230 KV ALBRAS - HYDRO ALUNORTE</option>
    <option value="138 - CONTENÇÃO DE ESTRUTURA 60/3 LD MRA X TMA - EQUATORIAL MA">138 - CONTENÇÃO DE ESTRUTURA 60/3 LD MRA X TMA - EQUATORIAL MA</option>
    <option value="139 - LD 69KV MATRIZ DE CAMARAGIBE / COSTA DOS CORAIS - EQUATORIAL AL">139 - LD 69KV MATRIZ DE CAMARAGIBE / COSTA DOS CORAIS - EQUATORIAL AL</option>
    <option value="140 - LD 69kV DELMIRO GOLVEIA - OLHO DAGUA DAS FLORES - EQUATORIAL AL">140 - LD 69kV DELMIRO GOLVEIA - OLHO DAGUA DAS FLORES - EQUATORIAL AL</option>
    <option value="141 - CONSTRUÇÃO SE BAGÉ 3 - EQUATORIAL RS">141 - CONSTRUÇÃO SE BAGÉ 3 - EQUATORIAL RS</option>
    <option value="142 - RENOVAÇÃO SE BAGÉ 1 - EQUATORIAL RS">142 - RENOVAÇÃO SE BAGÉ 1 - EQUATORIAL RS</option>
    <option value="143 - RENOVAÇÃO SE RIO GRANDE 1 - EQUATORIAL RS">143 - RENOVAÇÃO SE RIO GRANDE 1 - EQUATORIAL RS</option>
    <option value="144 - AMPLIAÇÃO SE SANTA VITÓRIA DO PALMAR - EQUATORIAL RS">144 - AMPLIAÇÃO SE SANTA VITÓRIA DO PALMAR - EQUATORIAL RS</option>
    <option value="145 - AMPLIAÇÃO SE TAIM 1 - EQUATORIAL RS">145 - AMPLIAÇÃO SE TAIM 1 - EQUATORIAL RS</option>
    <option value="146 - LD 138 KV OEIRAS X CAMETA - EQTL PARA">146 - LD 138 KV OEIRAS X CAMETA - EQTL PARA</option>
    <option value="147 - LD 69KV SÃO MIGUEL DOS CAMPOS X PERIPERI - EQUATORIAL AL">147 - LD 69KV SÃO MIGUEL DOS CAMPOS X PERIPERI - EQUATORIAL AL</option>
    <option value="148 - LPU AMAPÁ - EQUATORIAL AP">148 - LPU AMAPÁ - EQUATORIAL AP</option>
    <option value="149 - LT 69 KV CALHEIROS - BRASIL PCH - RJ">149 - LT 69 KV CALHEIROS - BRASIL PCH - RJ</option>              
    <option value="150 - LT PCH PIRAPETINGA X SE ITAPERUNA - ESSENTIA ENERGIA">150 - LT PCH PIRAPETINGA X SE ITAPERUNA - ESSENTIA ENERGIA</option>              
    <option value="151 - LD 69KV CAM1 - CAM3 - RS">151 - LD 69KV CAM1 - CAM3 - RS</option>   
    <option value="154 - LD JUSSARA/FAZ.CANADA - EQT - GO">154 - LD JUSSARA/FAZ.CANADA - EQT - GO</option>      
    <option value="155 - LD 138kV PCH Retiro Velho - Brasil PCH - GO">155 - LD 138kV PCH Retiro Velho - Brasil PCH - GO</option>      
    <option value="156 - SE BROTAS - 69 KV TIPO GIS - NEOENERGIA - BA">156 - SE BROTAS - 69 KV TIPO GIS - NEOENERGIA - BA</option>      
    <option value="157 - SERVIÇOS DE LPU - CONTRATO 570/2024 - ALAGOAS">157 - SERVIÇOS DE LPU - CONTRATO 570/2024 - ALAGOAS</option>      
    <option value="158 - LD 69kV CHARQUEADAS 3 - SECC CHA - GUA 1">158 - LD 69kV CHARQUEADAS 3 - SECC CHA - GUA 1</option>      
    
    <option value="MELOSA/PRESSOLUB">MELOSA/PRESSOLUB</option>    
  </Form.Select>
</Form.Group>
        </Row>
        <Row className="mb-3 align-items-center">
        <Form.Group as={Col} xs={12} md={2} controlId="cupom" className="mb-0" style={{ background:'#bcd1b3' }}>
            <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Cupom</Form.Label>
            <Form.Control
              type="text"
              placeholder="Cupom Fiscal"
              name="cupom"
              value={formData.cupom}
              onChange={handleInputChange}
              className="form-control form-control-sm"
              style={{ fontSize: '0.9rem' }}
            />
          </Form.Group>
       
          <Form.Group as={Col} xs={12} md={2} controlId="data" className="mb-0" style={{ background:'#bcd1b3' }}>
            <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Data</Form.Label>
            <Form.Control
              type="date"
              placeholder="Data abastecimento"
              name="data"
              value={formData.data}
              onChange={handleInputChange}
              className="form-control form-control-sm"
              style={{ fontSize: '0.9rem' }}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} md={2} controlId="veiculoTipo" className="mb-0"style={{ background:'#bcd1b3' }}>
  <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Tipo Veículo</Form.Label>
  <Form.Select
    placeholder="Tipo Veiculo"
    name="veiculoTipo"
    value={formData.veiculoTipo}
    onChange={handleInputChange}
    className="form-select form-select-sm"
    style={{ fontSize: '0.9rem' }}
  >
    <option selected value=""></option>
    <option value="Guindaste">Guindaste</option>
    <option value="Cabinado">Cabinado</option>
    <option value="Munck">Munck</option>
    <option value="Guindauto">Guindauto</option>
    <option value="Retroescavadeira">Retroescavadeira</option>
    <option value="Veículo Leve">Veículo Leve</option>
    <option value="Veículo Médio Porte">Veículo Médio Porte</option>
  </Form.Select>
</Form.Group>

          <Form.Group as={Col} xs={12} md={2} controlId="posto" className="mb-0" style={{ background:'#bcd1b3' }}>
            <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Posto</Form.Label>
            <Form.Control
              type="text"
              placeholder="Posto"
              name="posto"
              value={formData.posto}
              onChange={handleInputChange}
              className="form-control form-control-sm"
              style={{ fontSize: '0.9rem' }}
            />
          </Form.Group>
        </Row>
     
        <Row className="mb-3 align-items-center">
        <Form.Group as={Col} xs={12} md={2} controlId="motorista" className="mb-0" >
            <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Motorista</Form.Label>
            <Form.Control
              type="text"
              placeholder="Motorista"
              name="motorista"
              value={formData.motorista}
              onChange={handleInputChange}
              className="form-control form-control-sm"
              style={{ fontSize: '0.9rem' }}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} md={2} controlId="horimetro" className="mb-0">
            <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Horímetro</Form.Label>
            <Form.Control
              type="text"
              placeholder="horimetro"
              name="horimetro"
              value={formData.horimetro}
              onChange={handleInputChange}
              className="form-control form-control-sm"
              style={{ fontSize: '0.9rem' }}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} md={2} controlId="kmfinal" className="mb-0">
            <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Km</Form.Label>
            <Form.Control
              type="number"
              placeholder="Km"
              name="kmfinal"
              value={formData.kmfinal}
              onChange={handleInputChange}
              className="form-control form-control-sm"
              style={{ fontSize: '0.9rem' }}
            />
          </Form.Group>  
        <Form.Group as={Col} xs={12} md={2} controlId="formPlaca" className="mb-0">
            <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Placa</Form.Label>
            <Form.Control
              type="text"
              placeholder="Placa"
              name="placa"
              value={formData.placa}
              onChange={handleInputChange}
              className="form-control form-control-sm"
              style={{ fontSize: '0.9rem' }}
            />
          </Form.Group>
   
          
      
  
        </Row>
        
        <Row className="mb-3 align-items-center">
        <Form.Group as={Col} xs={12} md={2} controlId="combustivel" className="mb-0"style={{ background:'#bcd1b3' }}>
  <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Combustível</Form.Label>
  <Form.Select
    placeholder="Combustível"
    name="combustivel"
    value={formData.combustivel}
    onChange={handleInputChange}
    className="form-select form-select-sm"
    style={{ fontSize: '0.9rem' }}
  >  <option selected value=""></option>
    <option value="alcool">Álcool</option>
    <option value="gasolina">Gasolina</option>
    <option value="Gasolina Aditivada">Gasolina Aditivada</option>
    <option value="diesel">Diesel</option>
    <option value="diesel aditivado">Diesel Aditivado</option>
    <option value="diesel S10">Diesel S10</option>
    <option value="diesel S500">Diesel S500</option>
    <option value="oleo lubrificante">Óleo Lubrificante</option>
  </Form.Select>
</Form.Group>
        <Form.Group as={Col} xs={12} md={2} controlId="litros" className="mb-0" style={{ background:'#bcd1b3' }}>
            <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Litros</Form.Label>
            <Form.Control
              type="number"
              placeholder="Litros"
              name="litros"
              value={formData.litros}
              onChange={handleInputChange}
              className="form-control form-control-sm"
              style={{ fontSize: '0.9rem' }}
            />
          </Form.Group>
          
        <Form.Group as={Col} xs={12} md={2} controlId="valoruni" className="mb-0" style={{ background:'#bcd1b3' }}>
            <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Valor Unitário</Form.Label>
            <Form.Control
              type="number"
              placeholder="Valor Unitário"
              name="valoruni"
              value={formData.valoruni}
              onChange={handleInputChange}
              className="form-control form-control-sm"
              style={{ fontSize: '0.9rem' }}
            />
          </Form.Group>
        <Form.Group as={Col} xs={12} md={2} controlId="valor" className="mb-0" style={{ background:'#bcd1b3' }}>
  <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Valor Total</Form.Label>
  <Form.Control
    type="number"
    placeholder="Valor Total"
    name="valor"
    value={formData.valoruni*formData.litros}
    readOnly
    className="form-control form-control-sm"
    style={{ fontSize: '0.9rem' }}
  />
</Form.Group>


         
        </Row><Row className="mb-3">
        <Form.Group as={Col} xs={12} md={8} controlId="obscomb" className="mb-0">
            <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>Observação</Form.Label>
            <Form.Control
              type="textarea"
              placeholder="Observação"
              name="obscomb"
          
              value={formData.obscomb}
              onChange={handleInputChange}
              className="form-control form-control-sm"
              style={{ fontSize: '0.9rem' , height: '2cm'}}
            />
          </Form.Group>  
        {/* Add other form fields similarly for "Motorista", "Combustível", "Litros", "Valor Unitário", "Valor Total", "Km Anterior", "Km" */}
        </Row>
        <Row className="mb-3">
          <Col className="d-flex justify-content-start">
            <Button variant="dark" onClick={submitFormDataToFirebase} className="me-2">
              Enviar
            </Button>
            <Button variant="danger" onClick={deleteData}>
              Deletar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  </>
);
  
};

export default Compras;
